/**
 * Container for hero. Connect Redux to React props
 */

import { connect } from 'react-redux';
import Hero from '../components/hero/Hero.js';

const mapStateToProps = (state) => {
  if (state.hero.task) {
    return state.hero.task;
  }
  return {};
};

export default connect(mapStateToProps)(Hero);
