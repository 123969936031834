/**
 * Skills page
 */

import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import DocumentTitle from 'react-document-title';
import { IndexLink } from 'react-router';

const About = class extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.state = {
      currentEvent: 0,
      eventTime: Date.now()
    };
  }

  /**
   * Whell and scroll event, change year by scroll
   */
  handleScroll(event) {
    const delta = event.deltaX + event.deltaY;
    const prev = this.state.currentEvent - 1;
    const next = this.state.currentEvent + 1;
    const timestamp = Date.now();

    // New reaction only after 700 ms
    if (timestamp - this.state.eventTime < 700) {
      return false;
    }

    if (delta < -10 && this.props.events[prev]) {
      this.setState({ currentEvent: prev, eventTime: timestamp });
    } else if (delta > 10 && this.props.events[next]) {
      this.setState({ currentEvent: next, eventTime: timestamp });
    }

    return false;
  }

  handleClick(eventIndex) {
    this.setState({ currentEvent: eventIndex });
  }
  render() {
    // Events list
    const events = this.props.events.map((event, i) => {
      // Add css classes next and prev for siblings
      let cssClasses = 'year';
      if (this.state.currentEvent === i + 1) {
        cssClasses += ' prev';
      } else if (this.state.currentEvent === i - 1) {
        cssClasses += ' next';
      }
      if (i < this.state.currentEvent) {
        cssClasses += ' done';
      }

      // Render years
      if (i === this.state.currentEvent) {
        return <span key={event.year} className="year active">{event.year}</span>;
      }
      return (
        <span
          key={event.year}
          onClick={() => this.handleClick(i)}
          className={cssClasses}
        >
          {event.year}
        </span>
      );
    });

    // Render additional elements (like images, headers, call to action)
    const currentEvent = this.props.events[this.state.currentEvent];

    let img = false;
    if (currentEvent.imgSrc) {
      img = (
        <img
          src="/assets/images/me.png"
          alt="Yuri" style={{ width: 100, height: 100 }}
          className="face"
        />
      );
    }

    let title = false;
    if (currentEvent.title) {
      title = <h2>{currentEvent.title}</h2>;
    }

    let cta = false;
    if (currentEvent.cta) {
      cta = <IndexLink to={currentEvent.cta[0]}>{currentEvent.cta[1]}</IndexLink>;
    }

    const quote = (
      <div className="desc" key={this.state.currentEvent}>
        {img}{title}
        <p>{currentEvent.text}{cta}</p>
      </div>
    );

    return (
      <DocumentTitle title="About Me - Front End Developer Yuri Akimov">
        <div className="screen" id="about" onWheel={this.handleScroll}>
          <div className="text-center">
            <ReactCSSTransitionGroup
              transitionName="anim-quote"
              transitionEnterTimeout={850}
              transitionLeaveTimeout={100}
              component="div"
            >
              {quote}
            </ReactCSSTransitionGroup>
            <div id="timeline">
              {events}
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
};

About.propTypes = {
  events: React.PropTypes.array.isRequired
};

About.defaultProps = {
  events: [
    {
      year: 1988,
      imgSrc: '/assets/images/me.png',
      text: 'My name is Yuri. I was born in 1988 in Moscow, Russia. '
        + 'Now I\'m a senior front-end developer.'
    },
    {
      year: 2004,
      title: 'Freelance',
      text: 'I started making my first freelance money for Flash Animation, '
        + 'HTML and CSS work when I was a schoolchild.'
    },
    {
      year: 2007,
      title: 'Office Work',
      text: 'I got my first official job in IT industry '
        + 'and for the next 6 years I worked in the office of a system integrator company.'
    },
    {
      year: 2008,
      title: 'High School',
      text: 'I went to Moscow State Institute of Radio Engineering, Electronics and Automation.'
    },
    {
      year: 2010,
      title: 'Multitasking',
      text: 'I opened my own company, but I had to work in the office '
        + 'while I studied at the institute. It was not an easy time.'
    },
    {
      year: 2012,
      title: 'My First Diploma',
      text: 'After 4 years I got my Bachelor of Science in Information Technology.'
    },
    {
      year: 2013,
      title: 'Goodbye Office Work!',
      text: 'I was head of an IT team, but I have the soul of a freelancer, '
        + 'so this was the last year I worked in an office. I made a decision to work remotely.'
    },
    {
      year: 2014,
      title: 'My master\'s Degree',
      text: 'I got my master\'s degree and understood I like a front end much more then '
        + 'other work, since this year I can spend all my time to improve my skills.'
    },
    {
      year: 'Goal',
      title: 'Be the Best',
      text: 'I want to be a part of the best global team, one that\'s going to change the world. '
        + 'I hope it\'s you! ',
      cta: ['/', 'Contact Me...']
    }
  ]
};

export default About;
