import { combineReducers } from 'redux';
import hero from './hero';
import phase from './phase';

const rootReducer = combineReducers({
  hero,
  phase
});

export default rootReducer;
