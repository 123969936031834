import { combineReducers } from 'redux';

/**
 * What hero will do in the future
 */
const queue = (state = [], action) => {
  switch (action.type) {
    case 'HERO_QUEUE_ADD': {
      return [].concat(action.tasks, state);
    }
    case 'HERO_QUEUE_TASK_TOUCH': {
      return state.map((task, index) => {
        if (index === action.id) {
          const increaseSeconds = task.increaseSeconds || 0;
          return {
            ...task,
            weight: task.weight ? task.weight - 10 : -10,
            nextUse: action.nextUse + (increaseSeconds * 1000)
          };
        }
        return task;
      });
    }
    default: {
      return state;
    }
  }
};

/**
 * What Hero is doing now
 */
const task = (state = {}, action) => {
  switch (action.type) {
    case 'HERO_TASK_STOP': {
      return {};
    }
    case 'HERO_TASK_RUN': {
      return action.task;
    }
    default: {
      return state;
    }
  }
};

const hero = combineReducers({
  queue,
  task
});

export default hero;
