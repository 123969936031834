/**
 * Main menu
 */

import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Link, IndexLink } from 'react-router';
import { connect } from 'react-redux';
import * as actionCreators from '../../actions/phase';

const Menu = class extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      showMenu: false,
      links: [
        ['/', 'Home & Contacts'],
        ['skills', 'Skills'],
        ['about', 'About Me']
      ]
    };
  }
  handleClick() {
    if (!this.state.showMenu) {
      this.props.phaseAdd('menu');
    } else {
      this.props.phaseRemove();
    }
    this.setState({ showMenu: !this.state.showMenu });
  }
  render() {
    let menu = false;
    const links = this.state.links.map((link) => {
      if (link[0] === '/') {
        return <IndexLink key={link[0]} to={link[0]} activeClassName="active">{link[1]}</IndexLink>;
      }
      return <Link key={link[0]} to={link[0]} activeClassName="active">{link[1]}</Link>;
    });

    if (this.state.showMenu) {
      menu = (
        <div id="main-menu-dropdown" className="screen">
          <div>
            {links}
          </div>
        </div>
      );
    }

    return (
      <div onClick={this.handleClick}>
        <i id="main-menu" className="fi-list-bullet" onClick={() => this.handleClick} />
        <ReactCSSTransitionGroup
          transitionName="anim-fade"
          transitionEnterTimeout={400}
          transitionLeaveTimeout={300}
          component="div"
        >
          {menu}
        </ReactCSSTransitionGroup>
      </div>
    );
  }
};

Menu.contextTypes = {
  router: React.PropTypes.object.isRequired
};

Menu.propTypes = {
  phaseAdd: React.PropTypes.func.isRequired,
  phaseRemove: React.PropTypes.func.isRequired
};

export default connect(null, actionCreators)(Menu);
