/**
 * Home page
 */

import React from 'react';
import { Link } from 'react-router';
import DocumentTitle from 'react-document-title';
import ContactMe from './elements/ContactMe.js';

const Home = () =>
  <DocumentTitle
    title="Yuri Akimov - Front-End Expert. React, Single-Page Applications, UI/UX. Hire Freelancer"
  >
    <div className="screen">
      <div className="text-center">
        <h1><span>Front-End, React.js & </span><br /> Single-Page Applications</h1>
        <p>Hi, I’m Yuri Akimov and I’ve been a web developer since 2007.<br />
          For the last 4 years I've been experienced in front end technology:<br />
          JavaScript, HTML5, CSS3 and Responsive UI/UX. Look at <Link to="skills">my skills</Link>
        </p>
        <ContactMe />
      </div>
    </div>
  </DocumentTitle>;

export default Home;
