/**
 * Skill
 */

import React from 'react';

const Skill = ({ text, exp }) => {
  const factor = 2.2; // 220px = 100% exp
  return (
    <span className="skill" style={{ height: factor * 100 }}>
      <span className="exp" style={{ height: factor * exp, marginTop: factor * (100 - exp) }} />
      <div className="text">
        {text}
      </div>
    </span>
  );
};

Skill.propTypes = {
  text: React.PropTypes.string,
  exp: React.PropTypes.number
};

export default Skill;
