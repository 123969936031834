/**
 * Return next task from queue
 *
 * @param {queue} state.hero.queue
 * @param {phase} Current phase\action\step (path or something else in the window)
 * @return {nextTaskId} Next task id to run
 */
function getNextTask(queue, phase = false) {
  const now = new Date().getTime();
  let nextTaskId = false;
  let nextPhaseId = false;

  queue.forEach((task, index) => {
    const weight = task.weight || 0;

    // Don't use what already used recently
    if (task.nextUse && now < task.nextUse) {
      return;
    }

    if (phase && task.phase === phase
      && (nextPhaseId === false || queue[nextPhaseId].weight < weight)) {
      nextPhaseId = index;
    }

    if (!task.phase && (nextTaskId === false || queue[nextTaskId].weight < weight)) {
      nextTaskId = index;
    }
  });
  return nextPhaseId || nextTaskId;
}

/* Hero */
export const heroTaskRun = (task) => ({
  type: 'HERO_TASK_RUN',
  task
});

export const heroTaskStop = () => ({
  type: 'HERO_TASK_STOP'
});

export const heroQueueAdd = (tasks) => ({
  type: 'HERO_QUEUE_ADD',
  tasks
});

export const heroQueueTaskTouch = (id, nextUse = new Date().getTime() + (180 * 1000)) => ({
  type: 'HERO_QUEUE_TASK_TOUCH',
  id: Number(id),
  nextUse
});


export const heroNextTask = () => (dispatch, getState) => {
  const state = getState();
  const nextTaskId = getNextTask(state.hero.queue, state.phase);
  const nextTask = state.hero.queue[nextTaskId] || false;
  const nextTime = nextTask.text ? (nextTask.text.length / 10) + 2 : false;
  const now = new Date().getTime();

  // Run next task right now if phase changes or after until time
  if (nextTask && nextTaskId !== state.hero.task.id) {
    setTimeout(() => {
      dispatch(heroTaskRun({
        ...nextTask,
        id: nextTaskId,
        until: now + (nextTime * 1000)
      }));
      dispatch(heroQueueTaskTouch(state.hero.task.id));
    }, 150);
  } else if (state.hero.task.until < now) {
    dispatch(heroQueueTaskTouch(state.hero.task.id));
  }

  // Stop hero tasks if nothing to show
  if (!nextTask && state.hero.task.text) {
    dispatch(heroTaskStop());
  }

  // Next self-calling
  setTimeout(() => {
    dispatch(heroNextTask());
  }, 1000);
};

export const heroLoadAPI = () => (dispatch) => {
  dispatch(heroQueueAdd(
    [
      {
        text: 'Hi!',
        weight: 9,
        increaseSeconds: 1000
      },
      {
        text: 'I just loaded my AI',
        weight: 8,
        increaseSeconds: 1000
      },
      {
        text: 'My master is Yuri Akimov. He has created me and my home, all from scratch'
      },
      {
        text: 'JavaScript is my master’s primary language, '
          + 'but he knows many technologies around it'
      },
      {
        text: 'Look at this site on your desktop and mobile devices, it has an adaptive design',
        anim: ['mobile']
      },
      {
        text: 'Look at Yuri’s testimonials ',
        textCallback: ['link', 'https://www.upwork.com/freelancers/~01efc63bf5469987cc', 'on Upwork']
      },
      {
        text: 'I\'m a mix of JavaScript, React.js, Canvas and HTML5'
      },
      {
        text: 'Connect with Yuri ',
        textCallback: ['link', 'https://www.linkedin.com/in/oldwin/', ' via LinkedIn']
      },
      {
        text: '1001 0110 1011'
      },
      {
        text: 'Please, hire my master and he will give me more mind',
        anim: ['pointCenter']
      },
      {
        text: 'You should contact with Yuri if you need someone like me'
      },
      {
        text: 'Please choose a menu item',
        phase: 'menu',
        anim: ['pointCenter']
      }
    ]
  ));

  setTimeout(() => dispatch(heroNextTask()), 1700);
};
