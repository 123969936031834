/**
 * CTA with contacts
 */

import React from 'react';
import ReactGA from 'react-ga';

const logContactClick = (label) => {
  ReactGA.event({
    category: 'Contact',
    action: 'Click',
    label
  });
};

const ContactMe = () =>
  <p className="cta">
    <span>
      <a
        href="mailto:oldwin@gmail.com"
        className="social bg-blue"
        onClick={() => logContactClick('Email')}
      >
        <i className="fi-mail" />
      </a>
    </span>
    <span>
      <a
        href="skype:akimov_g?add"
        className="social bg-blue"
        onClick={() => logContactClick('Skype')}
      >
        <i className="fi-social-skype" />
      </a>
    </span>
    <span>
      <a
        href="https://www.linkedin.com/in/oldwin"
        target="_blank" rel="noopener noreferrer"
        className="social bg-blue"
        onClick={() => logContactClick('LinkedIn')}
      >
        <i className="fi-social-linkedin" />
      </a>
    </span>
    <span className="or"> or </span>
    <span>
      <a
        href="https://www.upwork.com/freelancers/~01efc63bf5469987cc"
        target="_blank"
        rel="noopener noreferrer"
        className="btn"
        onClick={() => logContactClick('Upwork')}
      >
        Hire Me on Upwork
      </a>
    </span>
  </p>;

export default ContactMe;
