/**
 * Index/Routing file
 */

import React from 'react';
import { render } from 'react-dom';
import { Router, Route, browserHistory, Redirect, IndexRoute } from 'react-router';
import { Provider } from 'react-redux';
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import ReactGA from 'react-ga';

import rootReducer from './reducers';
import App from './components/App';
import Home from './components/Home';
import Skills from './components/Skills';
import About from './components/About';

import { heroLoadAPI } from './actions';

window.React = React;

// Init Redux
const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  )
);
store.dispatch(heroLoadAPI());

// Google Analytics
ReactGA.initialize('UA-16976272-11');

const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
};

// Multi language routes
const routes = (
  <Route>
    <IndexRoute component={Home} />
    <Route path="skills" component={Skills} />
    <Route path="about" component={About} />
  </Route>
);

render(
  <Provider store={store}>
    <Router history={browserHistory} onUpdate={logPageView}>
      <Route path="/" component={App}>
        {routes}
      </Route>
      <Route path="/ru">
        {routes}
        <Redirect from="*" to="/ru" />
      </Route>
      <Redirect from="*" to="/" />
    </Router>
  </Provider>,
  window.document.getElementById('content')
);
