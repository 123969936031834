/**
 * What's happened on the window (current path, action, delay)
 */
const phase = (state = false, action) => {
  switch (action.type) {
    case 'PHASE_ADD': {
      return action.phase;
    }
    case 'PHASE_REMOVE': {
      return false;
    }
    default: {
      return state;
    }
  }
};

export default phase;
