/**
 * Skills page
 */

import React from 'react';
import { Link } from 'react-router';
import DocumentTitle from 'react-document-title';
import Skill from './elements/Skill';

const Skills = () =>
  <DocumentTitle title="Skills - Front End Developer Yuri Akimov">
    <div className="screen">
      <div className="text-center">
        <Skill text="JavaScript (ES6+)" exp={91} />
        <Skill text="HTML5" exp={81} />
        <Skill text="CSS3 (LESS/SCSS)" exp={93} />
        <Skill text="Responsive UI/UX" exp={95} />
        <Skill text="React JS/Redux" exp={98} />
        <Skill text="jQuery" exp={75} />
        <Skill text="Animation" exp={85} />
        <Skill text="Backend" exp={50} />
        <p className="max-width">
          Do you need single-page application?
          {' '}Do you need an UI/UX like this? <span className="medium-br" />
          I can help you with it! JavaScript is my primary language <span className="medium-br" />
          {' '}and I really love to work with React.js. <Link to="about">Who am I?</Link>
        </p>
      </div>
    </div>
  </DocumentTitle>;

export default Skills;
