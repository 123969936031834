/**
 * App main container
 */

import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import HeroContainer from '../containers/HeroContainer.js';
import Menu from './elements/Menu.js';

export default class App extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    const show = this.props.children
      ? React.cloneElement(this.props.children, { key: this.props.location.pathname })
      : '';

    return (
      <div>
        <ReactCSSTransitionGroup
          transitionName="anim-main"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}
          component="div"
        >
          <Menu />
          {show}
          <HeroContainer />
        </ReactCSSTransitionGroup>
      </div>
    );
  }
}

App.propTypes = {
  children: React.PropTypes.node,
  location: React.PropTypes.object.isRequired
};
