/**
 * Hero\robot
 *
 * @param {this.props.text} Text to current shown
 * @param {this.props.anim} Current animation
 */

import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

// Load canvas
import lib from './canvas/hero.js';

const createjs = window.createjs;

// Hero delay animations
const delay = [
  'blink',
  'blink', // Double priority
  'thunderbolt',
  'twoHands',
  'rightHand'
];

const randomAnimation = (anim) => anim[Math.floor(Math.random() * anim.length)];

const Hero = class extends React.Component {
  componentDidMount() {
    // Init CreateJS
    const canvas = this.canvas;

    this.stage = new createjs.Stage(canvas);
    this.stage.addChild(new lib.Hero());

    createjs.Ticker.addEventListener('tick', this.stage);
    createjs.Ticker.setFPS(24);
  }
  componentWillReceiveProps(nextProps) {
    // Run animation from props.anim or delay array
    clearInterval(this.timer);

    if (nextProps.anim) {
      const randomAnim = randomAnimation(nextProps.anim);
      lib.movie.currentAnimStatus = randomAnim;
      lib.movie.gotoAndPlay(randomAnim);
    } else {
      lib.movie.currentAnimStatus = false;
      this.handleDelay();
    }
  }
  handleDelay() {
    const timeout = Math.ceil((Math.random() * 2000)) + 2000;

    this.timer = setInterval(
      () => lib.movie.gotoAndPlay(randomAnimation(delay)),
      timeout
    );
  }
  handleTextCallback(task) {
    if (task.textCallback && task.textCallback[0]) {
      switch (task.textCallback[0]) {
        case 'link': {
          // External link
          let attr = {};
          if (task.textCallback[1].indexOf('http') === 0) {
            attr = { target: '_blank', rel: 'noopener noreferrer' };
          }
          return (
            <span>
              {task.text}
              <a href={task.textCallback[1]}{...attr}>{task.textCallback[2]}</a>
            </span>
          );
        }
        default: {
          return task.text;
        }
      }
    }
    return task.text;
  }
  render() {
    // Hero text
    let text = false;
    if (this.props.text) {
      text = (
        <span key={this.props.id} className="hero-text">
          {this.handleTextCallback(this.props)}
        </span>
      );
    }

    return (
      <div id="screen-hero">
        <canvas id="hero" ref={node => (this.canvas = node)} width={960} height={1280} />
        <ReactCSSTransitionGroup
          transitionName="anim-talk"
          transitionEnterTimeout={1000}
          transitionLeaveTimeout={950}
          component="span"
        >
          {text}
        </ReactCSSTransitionGroup>
      </div>
    );
  }
};

Hero.propTypes = {
  text: React.PropTypes.string,
  id: React.PropTypes.number
};

export default Hero;
